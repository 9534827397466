import { IonIcon, IonImg, IonMenuToggle, IonRow, IonText, isPlatform } from '@ionic/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import PayDescImage from '../../../img/abms-pay-desc.svg';
import ActivateProfileDescImage from '../../../img/activate-profile-desc.svg';
import interrogation from '../../../img/icons/interrogation-black.svg';
import { AbmsActions } from '../../../redux/actions/abmsActions';
import { WalletActivationActions } from '../../../redux/actions/walletActivationActions';
import { filesService } from '../../../services/files.service';
import { ABMSEntity } from '../../../shared/entity/abmsEntity';
import { CardInfoEntity } from '../../../shared/entity/cardInfo.module';
import { ReduxStateEntity } from '../../../shared/entity/reduxStateEntity';
import { UserEntity } from '../../../shared/entity/userEntity';
import Labels from '../../../shared/labels';
import AboutThat from '../../AboutThat/AboutThat';
import AccordionABMS from '../../AccordionABMS/AccordionABMS';
import NoInfoCard from '../../NoInfoCard/NoInfoCard';
import StatusTextComponent from '../../StatusTextComponent/StatusTextComponent';
import './ABMS.css';
const ABMS: React.FC<CardInfoEntity> = ({ title, zoom, showShareCard }) => {
	const user = useSelector<any, UserEntity>((state) => state.user!);
	const dispatch = useDispatch();
	const history = useHistory();
	const data = useSelector<any, any>((state) => state.wallet.data!);
	const walletActivation = useSelector<any, any>((state) => state.walletActivation!);
	const abmsData = useSelector<ReduxStateEntity, any>((state) => state.abms!);
	const abms = useSelector<ReduxStateEntity, ABMSEntity[]>((state) => abmsData.abms!);
	const [flip, setFlip] = useState(false);
	const [back, setBack] = useState('card card__abms');
	const [documentDescription, setDocumentDescription] = useState(abmsData?.info);
	const [meesageCard, setMessageCard] = useState(false);
	useEffect(() => {
		let isSubscribed = true;
		const verify = async () => {
			if (isSubscribed && data) {
				dispatch(WalletActivationActions.setWalletActivation(data));
			}
		};
		verify().catch(console.error);
		return () => {
			isSubscribed = false;
		};
	}, [data]);
	useEffect(() => {
		if (data) {
			const type = data.tenants[0].provider.type.name;
			if (type === 'MD' || type === 'DO') {
				dispatch(AbmsActions.setLicense(data, abmsData));
			} else {
				setMessageCard(true);
			}
		}
	}, [data]);
	useEffect(() => {
		if (abmsData?.info === '') {
			let isApiSubscribed = true;
			const tenantId = user.id;
			if (tenantId) {
				filesService.documentType(tenantId!, 'certificate').then(
					(res) => {
						if (isApiSubscribed) {
							if (res?.data) {
								res.data.documentFields.forEach((element: any) => {
									if (element.key === 'abmsdesc') {
										setDocumentDescription(element.description);
										dispatch(AbmsActions.setLicense(data, { info: element.description }));
									}
								});
							}
						}
					},
					(error) => {
						console.log('ERROR GETTING EXCLUSION TYPE:' + error);
					}
				);
				return () => {
					isApiSubscribed = false;
				};
			}
		}
	}, [abmsData]);
	useEffect(() => {
		if (abmsData?.info !== '') {
			setDocumentDescription(abmsData.info);
		}
	}, [abmsData, documentDescription]);
	const onFlip = () => {
		setFlip(!flip);
		if (flip) {
			setBack('card card__abms');
		} else {
			setBack('card card__abms abmsNoRotate');
		}
	};

	const AccordionContent = () => {
		return (
			<IonRow className="ion-margin full-width-abms">
				{abms.length > 1 ? (
					abms?.map((element, index) => (
						<AccordionABMS
							key={index}
							certifiedType={element.certificateType}
							title={element.specialty}
							active={element.status}
							lastItem={index === abms.length - 1 ? true : false}>
							<IonRow
								style={{
									width: '100%',
									display: 'flex',
									flexDirection: 'column',
									padding: '10px',
								}}
								key={index}>
								<IonText className="description-abms ">{element?.description}</IonText>
								<IonRow
									style={{
										width: '100%',
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
										marginTop: '16px',
									}}>
									<IonText className="title-content-text-abms-data">Status </IonText>
									<IonText className="title-content-text-abms-data">Efective </IonText>
									<IonText className="title-content-text-abms-data">Expiration </IonText>
								</IonRow>
								<IonRow
									style={{
										width: '100%',
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
									}}>
									<StatusTextComponent label={element.status!} />
									<IonText className="content-text-abms-data">
										{moment(element.effectiveDate).format('MMM DD YYYY')}
									</IonText>
									<IonText className="content-text-abms-data">{element.expirationDate}</IonText>
								</IonRow>
							</IonRow>
						</AccordionABMS>
					))
				) : (
					<IonRow
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'column',
							padding: '10px',
						}}>
						<IonText className="description-abms">{abms[0].certificateType}</IonText>
						<IonText className="title-abms ion-margin-bottom">{abms[0]?.specialty}</IonText>
						<IonText className="description-abms ">{abms[0]?.description}</IonText>
						<IonRow
							style={{
								width: '100%',
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
								marginTop: '16px',
							}}>
							<IonText className="title-content-text-abms-data">Status </IonText>
							<IonText className="title-content-text-abms-data">Efective </IonText>
							<IonText className="title-content-text-abms-data">Expiration </IonText>
						</IonRow>
						<IonRow
							style={{
								width: '100%',
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
							}}>
							<IonText className="content-text-abms-data">{Labels.label(abms[0]?.status)}</IonText>
							<IonText className="content-text-abms-data">
								{moment(abms[0]?.effectiveDate).format('MMM DD YYYY')}
							</IonText>
							<IonText className="content-text-abms-data">{abms[0]?.expirationDate}</IonText>
						</IonRow>
					</IonRow>
				)}
			</IonRow>
		);
	};
	const AllClearContent = () => {
		return (
			<IonRow className="center-no-info-card">
				<NoInfoCard textColor="text-color-black" blackIcon={true} cardType="abms" />{' '}
			</IonRow>
		);
	};
	const PaymentDescriptionContent = () => {
		return (
			<IonRow className="center-no-info-card">
				<div className="ion-padding">
					<IonImg src={PayDescImage} className="ion-margin"></IonImg>
				</div>
				<div className="ion-padding">
					{isPlatform('mobile') ? (
						<IonImg
							src={ActivateProfileDescImage}
							className="ion-margin"
							onClick={() => history.push('/tabs/activate-wallet')}></IonImg>
					) : (
						<IonMenuToggle>
							<IonImg
								src={ActivateProfileDescImage}
								className="ion-margin"
								onClick={() => history.push('/tabs/activate-wallet')}></IonImg>
						</IonMenuToggle>
					)}
				</div>
			</IonRow>
		);
	};

	return (
		<div className={back}>
			<div className={!walletActivation?.approved ? '' : 'container-abms'}>
				{!walletActivation?.payCompleted && !walletActivation?.approved ? (
					<IonRow className={`card___face ${!flip ? 'wrapper-zoom-abms ' : ''}`}>
						{!flip ? (
							<IonRow className="article-header-abms">
								<IonRow className="category-title">
									<IonText className="ion-padding-right">{title}</IonText>
								</IonRow>
							</IonRow>
						) : null}
						{!flip && (
							<>
								<PaymentDescriptionContent />
							</>
						)}
					</IonRow>
				) : (
					<IonRow className={`card___face ${!flip && !meesageCard ? 'wrapper-zoom-abms ' : ''}`}>
						{!flip ? (
							<IonRow className="article-header-abms">
								<IonRow className="category-title">
									<IonText className="ion-padding-right">{title}</IonText>
									{!flip && abms.length > 0 ? (
										<IonText className="bubble-abms">
											<span>{abms.length}</span>
										</IonText>
									) : null}
								</IonRow>
								<IonIcon
									id="about"
									className="icon-about-abms-actions"
									icon={interrogation}
									onClick={() => onFlip()}></IonIcon>
							</IonRow>
						) : null}
						{meesageCard ? (
							<IonText className="abms-message">This card is only applicable for MDs and DOs</IonText>
						) : !flip ? (
							abms.length > 0 ? (
								<AccordionContent />
							) : (
								<AllClearContent />
							)
						) : null}
					</IonRow>
				)}
				{flip ? (
					<AboutThat
						onFlip={onFlip}
						description={documentDescription}
						title={title}
						cssBackground="abms-background"
						cssTextColor="about-black"
					/>
				) : null}
			</div>
		</div>
	);
};

export default ABMS;
