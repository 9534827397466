import {
	IonButton,
	IonCard,
	IonContent,
	IonIcon,
	IonLoading,
	IonPage,
	IonRow,
	IonText,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import arrowBack from '../../img/icons/backArrow.svg';
import NucoLogo from '../../img/nuco-small-logo.svg';
import './providerType.css';

const ProviderType: React.FC<RouteComponentProps> = (props) => {
	const [toastIsShown, setToastIsShown] = useState(false);
	const [types, setTypes] = useState<any[]>([
		{ name: 'Medical Doctor (MD)', value: 'MD', selected: false },
		{ name: 'Doctor of Osteopathic Medicine (DO)', value: 'DO', selected: false },
		{ name: 'Physician Assistant (PA)', value: 'PA', selected: false },
		{ name: 'Nurse Practitioner (NP)', value: 'NP', selected: false },
		{ name: 'Registered Nurse (RN)', value: 'RN', selected: false },
	]);
	const [selected, setSelected] = useState(false);
	const [showLoading, setShowLoading] = useState(false);

	const navigate = (route: string) => {
		props.history.push(route);
	};

	const handleSubmit = (e: any, value: string) => {
		e.preventDefault();
		localStorage.setItem('nucoProvider', JSON.stringify(value));
		navigate('/terms');
	};

	const selectedCard = (card: any) => {
		setSelected(true);
		const tmp: any[] = [];
		types.forEach((element) => {
			if (element === card) {
				element.selected = true;
				tmp.push(element);
			} else {
				element.selected = false;
				tmp.push(element);
			}
		});
		setTypes(tmp);
	};

	return (
		<IonPage
			className="
      loginPage">
			<IonToolbar className="ion-no-padding ion-no-margin header-logo max-550">
				<IonRow className="title-img-header">
					<img src={NucoLogo} className="logo-img" />
				</IonRow>
			</IonToolbar>

			<IonContent className="ion-padding profile-page max-550">
				<IonToolbar className="no-background">
					<IonTitle className="ion-text-center" size="large">
						<IonIcon className="backArrowProfile" icon={arrowBack} onClick={() => props.history.goBack()}></IonIcon>
						Let’s get started
					</IonTitle>
				</IonToolbar>
				<IonRow className="ion-justify-content-center ion-padding-bottom">
					<IonRow className="pt-subtitle">Please select your Provider Type from the following list:</IonRow>
				</IonRow>

				<IonRow className="cards-container">
					{types?.map((element, index) =>
						element.value === 'NP' || element.value === 'RN' ? (
							<IonCard className={!element.selected ? 'pt-card' : 'pt-card pt-selected'} key={index}>
								<IonRow className={'pt-row-card space-title-np-rn'}>
									<IonText className="result-card-title">{element.name}</IonText>
									<IonText className="result-card-title-coming-soon">Coming Soon!</IonText>
								</IonRow>
							</IonCard>
						) : (
							<IonCard
								onClick={() => selectedCard(element)}
								className={!element.selected ? 'pt-card' : 'pt-card pt-selected'}
								key={index}>
								<IonRow className={'pt-row-card'}>
									<IonText className="result-card-title">{element.name}</IonText>
								</IonRow>
								{element.selected ? (
									<IonRow className="pt-saveTermsCard">
										{' '}
										<IonButton
											className={'buttonCardWIY'}
											disabled={!selected}
											color="secondary"
											shape="round"
											onClick={(e) => handleSubmit(e, element.value)}>
											{' '}
											Next{' '}
										</IonButton>
									</IonRow>
								) : null}
							</IonCard>
						)
					)}
				</IonRow>
			</IonContent>

			<IonLoading
				cssClass="spinner"
				isOpen={showLoading}
				onDidDismiss={() => setShowLoading(false)}
				spinner="crescent"
				translucent={true}
				duration={1000}
			/>
		</IonPage>
	);
};

export default ProviderType;
