import {
	IonContent,
	IonIcon,
	IonImg,
	IonInput,
	IonItem,
	IonLoading,
	IonPage,
	IonRow,
	IonText,
	IonTitle,
	IonToast,
	IonToolbar,
} from '@ionic/react';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import arrowBack from '../../img/icons/backArrow.svg';
import NucoLogo from '../../img/nuco-small-logo.svg';
import identityCard from '../../img/verifyEmail.svg';
import { walletActions } from '../../redux/actions';
import { onBoardingService } from '../../services/onBoarding.service';
import { ToastOptions } from '../../shared/entity/toastOptions';
import './confirmEmail.css';

import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { recaptchaSiteKey } from '../../config/staging';
const ConfirmEmail: React.FC<RouteComponentProps> = (props) => {
	const dispatch = useDispatch();
	const { executeRecaptcha } = useGoogleReCaptcha();
	const [toastIsShown, setToastIsShown] = useState(false);
	const [toast, setToast] = useState<ToastOptions>({});
	const [showLoading, setShowLoading] = useState(false);
	let resolved = useSelector<any, any>((state) => state.login!);
	const [inputs, setInputs] = useState({
		code1: '',
		code2: '',
		code3: '',
		code4: '',
	});
	const code1InputRef = useRef<HTMLIonInputElement | null>(null);
	const code2InputRef = useRef<HTMLIonInputElement | null>(null);
	const code3InputRef = useRef<HTMLIonInputElement | null>(null);
	const code4InputRef = useRef<HTMLIonInputElement | null>(null);

	const navigate = (route: string) => {
		props.history.push(route);
	};

	const handleSubmit = async (codeToSend: string) => {
		onBoardingService.confirmEmail(codeToSend).then(
			async (res) => {
				if (res?.data) {
					dispatch(walletActions.authorization(JSON.parse(localStorage.getItem('NuCoUserToken')!)));
					localStorage.setItem('NuCoUserStage', JSON.stringify('verify'));
					navigate('/verify-identity');
				} else {
					setToast({
						color: 'danger',
						header: 'Failure',
						message: 'Oops, something went wrong, try again',
					});
					showToast(toast);
				}
			},
			(error) => {
				setToast({
					color: 'danger',
					header: 'Failure',
					message: error.response.data,
				});
				console.log(error);
				showToast(toast);
			}
		);

		setShowLoading(true);
	};
	const handleChange = (e: any) => {
		const { name, value } = e.target;
		if (value.length === 4 && name === 'code1') {
			setInputs({
				code1: value.substring(0, 1),
				code2: value.substring(1, 2),
				code3: value.substring(2, 3),
				code4: value.substring(3, 4),
			});
			handleSubmit(value);
		} else {
			setInputs((inputs) => ({ ...inputs, [name]: value }));
		}
		sendCode(name, value);
	};
	const showToast = (toast: ToastOptions) => {
		setToastIsShown(true);
		setToast(toast);
	};

	const resend = async () => {
		if (!executeRecaptcha) {
			return;
		}
		const recaptchaToken = await executeRecaptcha('submit');

		onBoardingService.resendEmail(recaptchaToken).then(
			(res) => {
				if (res) {
					setToast({
						color: 'success',
						header: 'Success',
						message: 'check your email',
					});
					showToast(toast);
				} else {
					setToast({
						color: 'danger',
						header: 'Failure',
						message: 'Oops, something went wrong, try again',
					});
					showToast(toast);
				}
			},
			(error) => {
				setToast({
					color: 'danger',
					header: 'Failure',
					message: error.response.data,
				});
				console.log(error);
				showToast(toast);
			}
		);

		setShowLoading(true);
	};
	const sendCode = (currentInput: string, currentValue: string) => {
		const code =
			currentValue === ''
				? ''
				: currentValue.length > 3
				? currentValue
				: currentInput === 'code4'
				? inputs.code1 + inputs.code2 + inputs.code3 + currentValue
				: inputs.code1 + inputs.code2 + inputs.code3 + inputs.code4;
		if (code.length > 3) {
			handleSubmit(code);
		} else {
			setInputFocus(currentInput);
		}
	};
	const setInputFocus = (currentInput: String) => {
		setTimeout(() => {
			if ('code1' === currentInput) {
				code2InputRef.current?.setFocus();
			} else if ('code2' === currentInput) {
				code3InputRef.current?.setFocus();
			} else if ('code3' === currentInput) {
				code4InputRef.current?.setFocus();
			}
		}, 100);
	};
	return (
		<IonPage
			className="
      loginPage">
			<IonToolbar className="ion-no-padding ion-no-margin header-logo max-550">
				<IonRow className="title-img-header">
					<img src={NucoLogo} className="logo-img" />
				</IonRow>
			</IonToolbar>

			<IonContent className="ion-padding profile-page max-550">
				<IonToolbar className="no-background">
					<IonTitle className="ion-text-center" size="large">
						<IonIcon
							className="backArrowProfile"
							icon={arrowBack}
							onClick={() => {
								props.history.goBack();
								resolved.resolved = null;
								sessionStorage.setItem('backAction', 'true');
							}}></IonIcon>
					</IonTitle>
				</IonToolbar>

				<form className="ion-padding-bottom ion-padding-top flex-center-verify-email" name="form">
					<IonImg className="img-identify" src={identityCard}></IonImg>
					<IonRow className="ion-padding-bottom ion-padding-top flex-center-verify-email">
						<IonText className="check-email-text">Please check your email</IonText>
						<IonText>We sent you the details and a security code.</IonText>
					</IonRow>
					<IonText className="flex-center-verify-email">Paste it below to continue.</IonText>
					<IonRow className="row-input-code-verify">
						<IonItem className="input-inside ion-margin-top ion-margin-bottom input-code-verify">
							<IonRow className="row">
								<IonInput
									ref={code1InputRef}
									required
									name="code1"
									type="text"
									value={inputs.code1}
									onIonChange={handleChange}></IonInput>
							</IonRow>
						</IonItem>
						<IonItem className="input-inside ion-margin-top ion-margin-bottom input-code-verify">
							<IonRow className="row">
								<IonInput
									ref={code2InputRef}
									required
									name="code2"
									type="text"
									value={inputs.code1 ? inputs.code2 : ''}
									disabled={!inputs.code1}
									onIonChange={handleChange}></IonInput>
							</IonRow>
						</IonItem>
						<IonItem className="input-inside ion-margin-top ion-margin-bottom input-code-verify">
							<IonRow className="row">
								<IonInput
									ref={code3InputRef}
									required
									name="code3"
									type="text"
									value={inputs.code2 ? inputs.code3 : ''}
									disabled={!inputs.code2}
									onIonChange={handleChange}></IonInput>
							</IonRow>
						</IonItem>
						<IonItem className="input-inside ion-margin-top ion-margin-bottom input-code-verify">
							<IonRow className="row">
								<IonInput
									ref={code4InputRef}
									required
									name="code4"
									type="text"
									value={inputs.code3 ? inputs.code4 : ''}
									disabled={!inputs.code3}
									onIonChange={handleChange}></IonInput>
							</IonRow>
						</IonItem>
					</IonRow>
					<IonRow className="auto-center ion-padding add-padding">
						<IonText
							className="create-account-link send-code-again-color"
							onClick={() => {
								resend();
							}}>
							send code again
						</IonText>
					</IonRow>
				</form>
			</IonContent>
			<IonToast
				isOpen={toastIsShown}
				onDidDismiss={() => setToastIsShown(false)}
				message={toast?.message}
				position="top"
				color={toast?.color}
				header={toast?.header}
				duration={3000}
			/>
			<IonLoading
				cssClass="spinner"
				isOpen={showLoading}
				onDidDismiss={() => setShowLoading(false)}
				spinner="crescent"
				translucent={true}
				duration={1000}
			/>
		</IonPage>
	);
};

const ConfirmEmailPageWrapper = (props: any) => {
	return (
		<GoogleReCaptchaProvider reCaptchaKey={recaptchaSiteKey}>
			<ConfirmEmail {...props} />
		</GoogleReCaptchaProvider>
	);
};

export default ConfirmEmail;
